import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: '/',
        component: () => import('./views/Combine.vue')
        // component: () => import('./views/Home.vue')
        // component: () => import('./views/Show.vue')
    },
    {
        path: '/combine',
        component: () => import('./views/Combine.vue')
    },
    {
        path: '/trade',
        component: () => import('./views/Trade.vue')
    },
    {
        path: '/show',
        component: () => import('./views/Show.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;