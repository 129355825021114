import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons'
import vuetyped from 'vue3typed'
import "@/assets/font/font.css"
const app = createApp(App)

for (const name in ElIcons){
	app.component(name, ElIcons[name])
}


app.use(vuetyped).use(router).use(ElementPlus).mount('#app')