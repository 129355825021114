<template>



  <el-container style="height: 100vh;">
     <el-aside width="240px" style="background-color: #000000; border-right: #ffffff 1px solid">
      <el-image style="margin: 63px 27px 79px 27px;" :src="require('@/assets/image/promptis_logo.gif')"></el-image>
      <el-menu
          router
          background-color="#000000"
          text-color="#ffffff"
          :default-active="$route.path"
      >

<!--        <el-menu-item index="/combine">
          主页
        </el-menu-item>-->
        <el-menu-item index="/"> <!--/combine-->
          Combine / 提示词组合
        </el-menu-item>
        <el-menu-item>  <!--index="/trade"-->
          Trade / 提示词交易
        </el-menu-item>
<!--        <el-menu-item index="/show">
          作品精选
        </el-menu-item>-->
        <el-menu-item>
          Community / 设计社区
        </el-menu-item>
        <el-menu-item>
          Home / 我的
        </el-menu-item>


      </el-menu>
    </el-aside>

    <el-container>
<!--
      <el-header style="width: 100%;height: 60px;text-align: center; border-bottom: #ffffff 1px solid">
        <span>手机版导航</span>
      </el-header>
-->

      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

</script>


<style scoped>

/*
body{
    cursor: url('@/assets/image/promptis_logo.png'),default;
}
*/

/*
body {
  width: 100vw;
  height: 100vh;
  cursor: url(~@/assets/image/promptis_logo.png), auto;
}
*/

.el-menu {
  text-align: center;
  border: none;
}

.el-menu-item {
  display: block;
  color: #a1a1a1;
  font-size: medium;
}

.el-menu-item.is-active {
  display: block;
  color: #ffffff;
  font-size: medium;
}

.img {
  image-rendering:-moz-crisp-edges;
  image-rendering:-o-crisp-edges;
  image-rendering:-webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode:nearest-neighbor;
}

/*.el-menu {
  padding-left: 30px;
}*/

.el-menu-item {
  color: #a1a1a1;
  margin-top: 37px;
  font-size: large;
}

.el-menu-item:hover {
  color: #ffffff;
}

.el-menu-item.is-active {
  color: #ffffff;
  font-size: large;
}

.sidebar {
  border-right: solid 1px #ffffff;
  position: fixed;
  left: 0;
  height: 100%;
}
.el-menu {
  border: none;
}
.el-main {
  --el-main-padding: 0
}

</style>


<style>

*::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;
}
* {
  scrollbar-width: none;
}

</style>
